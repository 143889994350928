$bar-color: #b8c2cc !default;
$bar-stroke: #8D99A6 !default;
$border-color: #e0e0e0 !default;
$light-bg: #f5f5f5 !default;
$light-border-color: #ebeff2 !default;
$light-yellow: #fcf8e3 !default;
$text-muted: #666 !default;
$text-light: #555 !default;
$text-color: #333 !default;
$blue: #a3a3ff !default;
$handle-color: #ddd !default;

.taskchart {
	.grid-background {
		fill: none;
	}
	.grid-header {
		fill: rgba(255,255,255,.7);
		stroke: $border-color;
		stroke-width: 1.4;
	}
	.grid-row {
		fill: #ffffff;
	}
	.grid-row:nth-child(even) {
		fill: $light-bg;
	}
	.grid-row-header {
		fill: #ffffff;
	}
	.grid-row-header:nth-child(even) {
		fill: $light-bg;
	}

	.row-line {
		stroke: $light-border-color;
	}
	.row-line-sep {
		stroke: $border-color;
		stroke-width: 1.4;
	}
	.tick {
		stroke: $border-color;
		stroke-width: 0.2;
		&.thick {
			stroke-width: 0.4;
		}
	}
	.today-highlight {
		fill: $light-yellow;
		opacity: 0.5;
	}

	.arrow {
		fill: none;
		stroke: $text-muted;
		stroke-width: 1.4;
	}

	.bar {
		fill: $bar-color;
		stroke: $bar-stroke;
		stroke-width: 0;
		transition: stroke-width .3s ease;
		user-select: none;
	}
	.bar-progress {
		fill: $blue;
	}
	.bar-invalid {
		fill: transparent;
		stroke: $bar-stroke;
		stroke-width: 1;
		stroke-dasharray: 5;

		&~.bar-label {
			fill: $text-light;
		}
	}
	.bar-label {
		// mix-blend-mode: difference;
		fill: #fff;
		dominant-baseline: central;
		text-anchor: middle;
		font-size: 14px;
		font-weight: normal;
		transition: all 0.4s ease 0s;

		&.big {
			fill: $text-light;
			text-anchor: start;
			transition: all 0.4s ease 0s;
		}
	}

	.handle {
		fill: $handle-color;
		cursor: ew-resize;
		opacity: 0;
		visibility: hidden;
		transition: opacity .3s ease;
	}

	

	.bar-wrapper {
		cursor: pointer;
		outline: none;

		&:hover {
			.bar {
				fill: darken($bar-color, 10);
				// stroke:rgb(0,0,0);
				// stroke-width:1;
			}

			.bar-progress {
				fill: darken($blue, 10);
			}

		}

		&.active {
			.bar {
				fill: darken($bar-color, 10);
				// stroke:rgb(0,0,0);
				// stroke-width:1;
			}

			.bar-progress {
				fill: darken($blue, 10);
			}

			.bar-active-border {
				fill: rgba(0, 0, 0, 0.8);
			}

			.handle {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	.lower-text, .upper-text {
		font-size: 12px;
		text-anchor: middle;
	}
	.upper-text {
		fill: $text-light;
	}
	.lower-text {
		fill: $text-color;
	}

	.hide {
		display: none;
	}
}

.taskchart-container {
	position: relative;
	overflow: auto;
	font-size: 12px;

	.popup-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.8);
		padding: 0;
		color: #959da5;
		border-radius: 3px;

		.title {
			border-bottom: 3px solid $blue;
			padding: 10px;
		}

		.subtitle {
			padding: 10px;
			color: #dfe2e5;
		}

		.pointer {
			position: absolute;
			height: 5px;
			margin: 0 0 0 -5px;
			border: 5px solid transparent;
			border-top-color: rgba(0, 0, 0, 0.8);
		}
	}
}